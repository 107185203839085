@import 'colors.scss';
@import 'sizes.scss';

.container.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 375px;
  padding: 50px 30px 0;
}

.header {
  font-size: 30px;
  font-weight: 700;
  line-height: 35px;
  text-align: center;
  color: var(--color-cool-purple-700);
}

.emailSignup {
  width: 100%;
}

.legal {
  margin: 32px 0;
  font-size: 14px;
  text-align: center;
  max-width: 100%;
}

.form {
  width: 100%;
  margin-top: 40px;
}

.inputLabel {
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 5px;
  color: var(--color-cool-purple-600);
}

.input {
  width: 100%;
  max-width: 400px;
  height: 50px;
  border: 0;
  border-radius: 6px;
}

.emailInput {
  margin-top: 30px;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: auto;
}

.goBack {
  margin-top: 14px;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
}
