@import 'breakpoints.scss';

.campaignDetails {
  margin-top: 50px;
}

.headline2 {
  font-family: Averta, sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
  margin: 0;
}

.blurb {
  font-family: Averta, sans-serif;
  font-size: 16px;
  line-height: 23px;
  color: #77757b;
  max-width: 20ch;
  margin-top: 6px;
}

.eyebrowLarge {
  font-family: Averta, sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.2em;
  color: #77757b;
  margin: 0;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 0 30px;
}

//  ----- INSTRUCTIONS SECTION -----

.challengeInstructions {
  width: 100%;
  display: flex;
  position: relative;
  height: 500px;
  margin-top: 50px;
  padding-left: 20px;
  overflow: hidden;

  .mt100 {
    margin-top: 100px;
  }

  .activities {
    position: absolute;
    right: 0;
    top: 35%;
    height: 256px;
    width: 366px;
    z-index: 5;
  }

  .phone {
    width: 153px;
    height: 490px;
    position: absolute;
    right: 0;
  }
}

//  ----- GUIDANCE SECTION -----

.guidanceContainer {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

.guidanceDescription {
  padding-left: 20px;
  margin-bottom: 30px;
}

.guidancePromise {
  color: #6a1099;
  text-align: left;
}

.coachGuidance {
  background: #34258d;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 15px 0 15px 15px;
  overflow: hidden;
  font-family: Averta, sans-serif;
  font-size: 16px;
  line-height: 23px;
  border-top-left-radius: 70px;
  border-bottom-left-radius: 70px;
  width: fit-content;
  align-self: flex-end;

  .textWhite {
    color: #ffffff;
  }
}

//  ----- TESTIMONIAL SECTION -----

.userSection {
  display: flex;
  gap: 10px;
  align-items: center;
}

.userImage {
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.userName {
  p {
    margin: 0;
  }
}

.testimonialContainer {
  padding: 20px;

  @include from(2) {
    max-width: 400px;
    margin: 0 auto;
  }
}

.testimonialCard {
  padding: 30px;
  background-color: #ecefff;
  border-radius: 20px;
  position: relative;
  margin-bottom: 20px;
}

.testimonials.testimonials {
  max-width: 29ch;
}

.testimonialCard::before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  border-left: 24px solid #ecefff;
  border-right: 12px solid transparent;
  border-top: 12px solid #ecefff;
  border-bottom: 20px solid transparent;
  left: 20px;
  bottom: -24px;
}
