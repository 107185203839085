@import 'colors.scss';
@import 'sizes.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 375px !important;
  padding: 30px 30px 0;
}

.divider {
  width: 100%;
  margin-bottom: $dividerSpacing;
}

.header {
  margin-bottom: 12px;
  font-size: 34px;
  text-align: center;
}

.subHeader {
  margin-bottom: 60px;
  color: $grey900;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}

.apple.apple {
  display: flex;
  width: 100%;
  margin-bottom: $buttonSpacing;
}

.facebook.facebook {
  display: flex;
  width: 100%;
  margin-bottom: $buttonSpacing;
}

.google.google {
  width: 100%;
  margin-bottom: $buttonSpacing;
  border: 1px solid $grey200;
}

.googleV2.googleV2 {
  margin-bottom: $buttonSpacing;
}

.emailSignup.emailSignup {
  width: 100%;
  margin-top: $buttonSpacing;
}

.emailLogin {
  width: 100%;
  margin-top: 24px;
  text-align: center;
}

.legal {
  margin: 32px 0;
  font-size: 14px;
  text-align: center;
}

.form {
  width: 100%;
}

.input {
  padding: 6px 12px;
  font-size: 14px;
}

@each $theme, $themeColors in $themes {
  $secondaryColor: map-get($themeColors, 'secondary');
  $authBackground: map-get($themeColors, 'authBackground');

  .#{$theme}.header {
    color: $secondaryColor;
  }
}
