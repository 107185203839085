@import 'colors.scss';
@import 'sizes.scss';

.container.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 375px;
  padding: 30px 30px 0;
}

.header {
  font-size: 25px;
  line-height: 36px;
  text-align: center;
}

.emailSignup {
  width: 100%;
}

.legal {
  margin: 32px 0;
  font-size: 14px;
  text-align: center;
}

.form {
  width: 100%;
  margin-top: 40px;
}

.inputLabel {
  font-size: 20px;
  line-height: 38px;
  text-align: center;
}

.input {
  width: 100%;
  max-width: 400px;
  height: 50px;
  border: 0;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 400;
  line-height: 38px;
  text-align: center;
}

.emailInput {
  margin-top: 30px;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: auto;
}

.goBack {
  margin-top: 14px;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
}
