@import 'colors.scss';
@import 'breakpoints.scss';

.wrapper {
  background-color: $white;

  h1 {
    font-size: 29px;
    font-weight: 400;
    font-family: Averta, sans-serif;
  }

  @include from(2) {
    max-width: 700px;
    border-radius: 20px;
    box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    margin: 20px auto;
  }
}

.divider {
  background-color: #f5f5f5;
  height: 2px;
  width: 100%;
  margin: 50px 0;
}

.hero {
  img {
    width: 100%;
    max-width: 100%;
  }
}

.gameDetails {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
}

.headerHighlight {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #effcf5;
  height: 50px;
  color: #2c8556;

  @include from(2) {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

.joinDetails {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-right: 20px;
}

.coachRow {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin: 0 10px;

  .coachImg {
    width: 55px;
    height: 55px;
    border-radius: 50%;
  }

  .coachDetails {
    display: flex;
    flex-direction: column;
    gap: 5px;

    p {
      padding: 0;
      margin: 0;
    }
  }
}
