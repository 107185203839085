@import 'colors.scss';

.outerContainer {
  display: flex;
  justify-content: center;
  background-color: themeColor('waybetter', 'light');
}

.container {
  display: grid;
  grid-row-gap: 40px;
  max-width: 375px;
  padding: 30px 40px;
}

.heading {
  font-size: 30px;
  line-height: 38px;
  text-align: center;
}

.innerContainer {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: auto auto;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 96px;
  border: 1px solid $grey75;
  border-radius: 10px;
  background-color: $white;
}

.questionContainer {
  display: grid;
  grid-row-gap: 12px;
}

.actionContainer {
  display: grid;
}

.betFooter {
  margin-top: 10px;
  font-size: 14px;
  justify-self: center;
}

.noBetButton {
  margin-top: 20px;
  justify-self: center;
  font-size: 18px;
  font-weight: bold;
}
