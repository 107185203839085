@import 'colors.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 100dvh;
  padding: 40px 30px 100px;
  background-color: #ecefff;
}

.container h1 {
  color: #34258d;
  font-size: 36px;
  font-weight: 800;
  line-height: 1.3;
}

.container p {
  color: #000000;
}

.wrapper {
  max-width: 650px;
}

.logoContainer {
  display: inline-block;

  .logo {
    width: 170px;
    height: 30px;
    object-fit: contain;
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
}

.header {
  position: relative;
  top: 20px;

  h4 {
    font-family: Averta;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

.avatar {
  display: flex;
  align-items: center;
  margin: 24px 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;

  img {
    width: 40px;
    margin-right: 10px;
  }
}

.qrCode {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 0;

  h4 {
    margin-bottom: 24px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

.socialProofContainer {
  display: grid;
  gap: 1rem;
  margin-top: 50px;

  img {
    width: 35%;

    @media screen and (width <= 575px) {
      width: 50%;
    }
  }
}

.signupFormContainer {
  position: relative;
  max-width: 650px;

  .twitterBird {
    position: relative;
    top: 30px;
    left: 80%;
    height: 50px;

    img {
      height: 100%;
    }
  }
}
