@import 'colors.scss';
@import 'sizes.scss';

.outerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 375px;
  padding: 30px 30px 0;
}

.header {
  margin: 65px 0;
  font-size: 34px;
  text-align: center;
}

.divider {
  margin-bottom: $dividerSpacing;
}

.apple.apple {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: $buttonSpacing;
}

.google.google {
  width: 100%;
  margin-bottom: $buttonSpacing;
  border: 1px solid $grey200;
}

.googleV2.googleV2 {
  margin-bottom: $buttonSpacing;
}

.facebook.facebook {
  display: flex;
  width: 100%;
  margin-bottom: $dividerSpacing;
}

.widget {
  margin-bottom: 30px;
}

.forgotPassword {
  font-size: 13px;
}

.signup {
  margin-top: $dividerSpacing;
}

.signupLink {
  font-weight: 600;
}

@each $theme, $themeColors in $themes {
  $secondaryColor: map-get($themeColors, 'secondary');
  $background: map-get($themeColors, 'lightBackground');

  .#{$theme}.header {
    color: $secondaryColor;
  }
}
