@import 'colors.scss';
@import 'breakpoints.scss';

.joinButtonContainer {
  display: flex;

  .joinButton.joinButton {
    width: 100%;

    @include from(2) {
      width: 350px;
    }
  }
}
