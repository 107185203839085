@import 'colors.scss';

.container {
  overflow: hidden;
  margin-right: -20px;
}

.scrollPane {
  width: 100vw;
  white-space: nowrap;
  overflow-x: auto;
  display: flex;
  padding: 10px 20px 10px 0;
}

.gameSelectorPill {
  flex: 0 0 auto;
  display: inline-block;
  padding: 10px 16px;
  border-radius: 10px;
  background-color: themeColor('waybetter', 'background');
  font-size: 14px;
  line-height: 21px;
  margin-right: 10px;
  color: #77757b;
  transition: background-color 0.3s ease;
  cursor: pointer;

  &:active {
    background-color: #e6eafa;
  }

  &.selected {
    background-color: #d3dcff;
    color: themeColor('waybetter', 'secondary');
    cursor: default;
  }
}
